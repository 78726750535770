import React from "react";
import { Helmet } from "react-helmet";
import { Button } from "../components";
import Footer from "../components/Footer";
import FormulaireDesktopSenseiFeatures from "../components/FormulaireDesktopSenseiFeatures";
import Header from "../components/Header";
import HomepageDesktopFeatures from "../components/HomepageDesktopFeatures";
import HomepageDesktopHerosection from "../components/HomepageDesktopHerosection";
import HomepageDesktopLogobar from "../components/HomepageDesktopLogobar";
import HomepageDesktopProjects from "../components/HomepageDesktopProjects";
import { useNavigate } from "react-router-dom";

export default function SenseiPage() {

  const navigate = useNavigate()

  const gotToNewPage=()=>{
    navigate("https://cal.com/sensei1/financement");
  }
  return (
    <>
      <Helmet>
        <title>sensei</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-gray-50_01">
        <Header className="p-5 bg-gray-50" />
        <HomepageDesktopHerosection className="flex flex-col items-center justify-end gap-10 p-[55px] md:p-5 bg-gray-50" />
        <HomepageDesktopLogobar className="pt-[55px] px-[55px] md:pt-5 md:px-5" />
        <HomepageDesktopFeatures className="flex flex-col items-center justify-center gap-[60px] p-[55px] md:p-5 sm:gap-[30px] bg-gray-50" />
        <HomepageDesktopProjects className="flex flex-col gap-[61px] p-[55px] md:p-5 sm:gap-[30px] bg-gray-50" />
        <div className="flex flex-col items-center bg-gray-50_01">
          <FormulaireDesktopSenseiFeatures
            description="Rejoignez Sensei dès aujourd'hui et soyez acteur de la révolution éducative. Ensemble, nous pouvons changer la vie des étudiants en les équipant pour poursuivre leur éducation et réaliser leurs rêves."
            className="flex md:flex-col self-stretch justify-between items-center gap-5 p-[55px] md:p-5 bg-gray-50"
          />
          <div className="flex justify-center w-[17%] md:w-full pb-[45px] md:pb-5 md:p-5">
            <div className="w-full">
              <Button onClick={() => gotToNewPage()} color="teal_600" size="md" className="w-full sm:px-5 font-poppins font-semibold rounded-[20px]">
                Financer ma formation
              </Button>
            </div>
          </div>
          <Footer className="self-stretch p-6 sm:p-5 bg-blue_gray-900_01" />
        </div>
      </div>
    </>
  );
}
