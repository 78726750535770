import React from "react";
import { Text, Heading, Img } from "./..";


export default function HomepageDesktopProjects({
  avantagesdu = "Avantages de l'Accompagnement Sensei",
  lefuturde = "Le futur de l'éducation",
  projecttitle = "Accessibilité ",
  short = "Sensei aide à surmonter les barrières financières, offrant un moyen alternatif d'accéder à l'éducation supérieure.",
  projecttitle1 = "Sécurité",
  shortdescriptio = "Nous assurons la sécurité des informations et l'allocation équitable des ressources.",
  projecttitletwo = "Communauté engagée",
  description = " Nous favorisons la collaboration entre étudiants, donateurs et institutions éducatives, pour soutenir l'éducation et aider les étudiants à atteindre leurs objectifs.",
  ...props
}) {
  return (
    <div {...props}>
      <div className="flex flex-col items-center w-full mt-[59px] gap-[19px] px-[173px] mx-auto md:px-5 max-w-[1170px]">
        <Heading size="md" as="h1" className="!text-black-900 tracking-[-0.76px] uppercase text-center">
          {avantagesdu}
        </Heading>
        <Text size="s" as="p" className="text-center">
          {lefuturde}
        </Text>
      </div>
      <div className="self-stretch w-full mb-[65px] mx-auto max-w-[1170px]">
        <div className="flex md:flex-col gap-[30px]">
          <div className="flex flex-col w-full pb-[30px] gap-[25px] sm:pb-5 bg-white-A700 shadow-sm rounded-[20px]">
            <Img
              src="images/img_image_placeholder.png"
              alt="image_one"
              className="h-[278px] rounded-tr-[20px] rounded-tl-[20px] object-cover"
            />
            <div className="flex flex-col items-center gap-2.5 px-[30px] sm:px-5">
              <Heading size="s" as="h2" className="tracking-[-0.28px] text-center">
                {projecttitle}
              </Heading>
              <Text as="p" className="text-center leading-[150%]">
                {short}
              </Text>
            </div>
          </div>
          <div className="flex flex-col w-full pb-[30px] gap-[25px] sm:pb-5 bg-white-A700 shadow-sm rounded-[20px]">
            <Img
              src="images/img_imageplaceholder1.png"
              alt="imageplaceholde"
              className="h-[278px] rounded-tr-[20px] rounded-tl-[20px] object-cover"
            />
            <div className="flex flex-col items-center gap-2.5 px-[30px] sm:px-5">
              <Heading size="s" as="h3" className="tracking-[-0.28px] text-center">
                {projecttitle1}
              </Heading>
              <Text as="p" className="text-center leading-[150%]">
                {shortdescriptio}
              </Text>
            </div>
          </div>
          <div className="flex flex-col w-full pb-[30px] gap-[30px] sm:pb-5 bg-white-A700 shadow-sm rounded-[20px]">
            <Img
              src="images/img_image_placeholder_278x370.png"
              alt="image_one"
              className="h-[278px] rounded-tr-[20px] rounded-tl-[20px] object-cover"
            />
            <div className="flex flex-col gap-[11px] px-[30px] sm:px-5">
              <Heading size="s" as="h4" className="tracking-[-0.28px] text-center leading-[130%]">
                {projecttitletwo}
              </Heading>
              <Text as="p" className="text-center leading-[150%]">
                {description}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
