import React from "react";
import { Text, Button, Img, Input, Heading } from "./..";

export default function FormulaireDesktopSenseiFeatures({
  createfromOne,
  buttonOne,
  buttonThree,
  buttonFive,
  buttonSeven,
  insertyourOne,
  topspeakerOne,
  formulaire = "Formulaire",
  description = "Rejoignez SENSEI dès aujourd’hui et participez à la révolution éducative. Ensemble, nous pouvons changer la vie des étudiants en leur donnant les moyens de poursuivre leur éducation et de réaliser leurs rêves.",
  voustesind = "Vous êtes indépendant",
  travailleur = "Travailleur temporaire",
  voustesbn = "Vous êtes bénéficiaire de l’aide sociale",
  reconversion = "Reconversion professionnelle",
  demandeurd = (
    <>
      Demandeur d’emploi inscrit
      <br />à l’assurance -chômage
    </>
  ),
  iconOne,
  voustesen,
  ...props
}) {
  return (
    <div {...props}>
      <div className="h-[450px] w-[46%] md:w-full p-[15px] relative">
        <div className="flex items-start w-[89%] h-max left-[8%] bottom-0 top-0 my-auto absolute">
          <Img src="images/img_subtract.svg" alt="subtract_one" className="h-[126px] mt-[63px] z-[1]" />
          <div className="flex flex-col items-end justify-end ml-[-50px] p-3.5 bg-orange-A100 flex-1 rounded-[189px]">
            <Img src="images/img_subtract_2.svg" alt="subtract_three" className="h-[63px] mt-[193px] mb-[92px]" />
          </div>
        </div>
        {!!createfromOne ? (
          <div className="h-[411px] w-[42%] left-0 bottom-0 right-0 top-0 m-auto shadow-md absolute rounded-[29px]" />
        ) : null}
        <div className="flex justify-between items-center w-[44%] top-[16%] right-0 left-0 gap-5 m-auto absolute">
          <div className="w-[3%]">
            {!!buttonOne ? (
              <div className="h-[17px] w-[6px] border-blue_gray-100 border border-solid bg-gradient rounded-sm" />
            ) : null}
            {!!buttonThree ? (
              <div className="h-[38px] w-[6px] mt-2.5 border-blue_gray-100 border border-solid bg-gradient rounded-sm" />
            ) : null}
            {!!buttonFive ? (
              <div className="h-[38px] w-[6px] mt-[5px] border-blue_gray-100 border border-solid bg-gradient rounded-sm" />
            ) : null}
          </div>
          {!!buttonSeven ? (
            <div className="h-[52px] w-[6px] border-blue_gray-100 border border-solid bg-gradient rounded-sm" />
          ) : null}
        </div>
        <div className="w-[43%] h-max left-0 bottom-0 right-0 top-0 p-[7px] m-auto border-blue_gray-100 border border-solid bg-gradient absolute rounded-[38px]">
          <div className="h-[401px] md:h-auto border-blue_gray-900_01 border border-solid shadow-bs relative rounded-[32px]">
            {!!insertyourOne ? (
              <Img src={insertyourOne} alt="insertyour_one" className="h-[400px] w-full object-cover" />
            ) : null}
            {!!topspeakerOne ? (
              <Img
                src={topspeakerOne}
                alt="topspeaker_one"
                className="h-[14px] top-[5.70px] right-0 left-0 m-auto absolute"
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-[49%] md:w-full gap-[25px] my-[65px]">
        <div className="flex flex-col items-start gap-[17px]">
          <Heading size="md" as="h1" className="tracking-[-0.76px]">
            {formulaire}
          </Heading>
          <Text as="p" className="leading-[150%]">
            {description}
          </Text>
        </div>
        <div className="flex flex-col gap-5 shadow-sm rounded-[20px]">
          <div className="flex sm:flex-col justify-center items-start gap-5">
            <Input
              shape="round"
              name="cardinfo_one"
              placeholder={`Vous êtes salarié`}
              prefix={
                <div className="flex justify-center items-center w-[50px] h-[50px] bg-teal-50 rounded-[12px]">
                  <Img src="images/img_user2.svg" alt="User" />
                </div>
              }
              className="w-full sm:w-full"
            />
            <div className="flex justify-center items-center w-full sm:w-full gap-5 p-5 border-teal-50 border border-solid bg-white-A700 rounded-[20px]">
              <Button color="teal_50" size="sm" shape="round" className="w-[50px]">
                <Img src="images/img_icon_6.svg" />
              </Button>
              <div className="flex-1">
                <Text as="p" className="leading-[150%]">
                  {voustesind}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex sm:flex-col justify-center items-start gap-5">
            <div className="flex justify-center items-center w-full sm:w-full gap-5 p-5 border-teal-50 border border-solid bg-white-A700 rounded-[20px]">
              <Button color="teal_50" size="sm" shape="round" className="w-[50px]">
                <Img src="images/img_icon_7.svg" />
              </Button>
              <div className="flex-1">
                <Text as="p" className="leading-[150%]">
                  {travailleur}
                </Text>
              </div>
            </div>
            <Input
              shape="round"
              name="cardinfo_three"
              placeholder={`Étudiants`}
              prefix={
                <div className="flex justify-center items-center w-[50px] h-[50px] bg-teal-50 rounded-[12px]">
                  <Img src="images/img_scroll.svg" alt="Scroll" />
                </div>
              }
              className="w-full sm:w-full sm:pr-5"
            />
          </div>
        </div>
        <div className="justify-center gap-5 grid-cols-[repeat(auto-fill,_minmax(235px_,_1fr))] shadow-sm grid rounded-[20px]">
          <div className="flex sm:flex-col justify-center items-center w-full gap-5 p-5 border-teal-50 border border-solid bg-white-A700 rounded-[20px]">
            <Button color="teal_50" size="sm" shape="round" className="w-[50px] sm:p-5">
              <Img src="images/img_icon_7.svg" />
            </Button>
            <div className="sm:self-stretch flex-1">
              <Text as="p" className="leading-[150%]">
                {voustesbn}
              </Text>
            </div>
          </div>
          <div className="flex justify-center items-center w-full gap-5 p-5 border-teal-50 border border-solid bg-white-A700 rounded-[20px]">
            <Button color="teal_50" size="sm" shape="round" className="w-[50px]">
              <Img src="images/img_icon_10.svg" />
            </Button>
            <div className="flex-1">
              <Text as="p" className="leading-[150%]">
                {reconversion}
              </Text>
            </div>
          </div>
          <div className="flex justify-center items-center w-full gap-5 p-5 border-teal-50 border border-solid bg-white-A700 rounded-[20px]">
            <Button color="teal_50" size="sm" shape="round" className="w-[50px]">
              <Img src="images/img_icon_11.svg" />
            </Button>
            <div className="flex-1">
              <Text as="p" className="leading-[150%]">
                {demandeurd}
              </Text>
            </div>
          </div>
          <div className="flex justify-center items-center w-full gap-5 p-5 border-teal-50 border border-solid bg-white-A700 rounded-[20px]">
            {!!iconOne ? (
              <Button color="teal_50" size="sm" shape="round" className="w-[50px]">
                <Img src="images/img_icon.svg" />
              </Button>
            ) : null}
            <div className="flex-1">
              {!!voustesen ? (
                <Text as="p" className="leading-[150%]">
                  {voustesen}
                </Text>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
