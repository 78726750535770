import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
//import Homepagedesktop from "pages/Homepagedesktop";
//import FormulairedesktopSensei from "pages/FormulairedesktopSensei";
//import FundScore from "pages/FundScore";
//import Sensei from "pages/Sensei";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
    /*{
      path: "homepagedesktop",
      element: <Homepagedesktop />,
    },
    {
      path: "formulairedesktopsensei",
      element: <FormulairedesktopSensei />,
    },
    {
      path: "fundscore",
      element: <FundScore />,
    },
    {
      path: "sensei",
      element: <Sensei />,
    },*/
  ]);

  return element;
};

export default ProjectRoutes;
