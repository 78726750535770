import React from "react";
import { Button, Img, Heading } from "./..";

export default function Header({ ...props }) {
  return (
    <header {...props}>
      <div className="flex sm:flex-col justify-between items-center w-full gap-5 mx-auto max-w-[1180px]">
        <div className="flex gap-1.5">
          <Img src="images/img_logo_mark.svg" alt="logomark_one" className="self-start h-[32px] w-[32px]" />
          <Heading as="h5" className="self-end text-center !font-bold">
            <span className="text-blue_gray-900_01">Landing</span>
            <span className="text-blue_gray-900_01 font-normal">Page</span>
          </Heading>
        </div>
        <Button
          color="teal_600"
          size="md"
          leftIcon={<Img src="images/img_rocketlaunch.svg" alt="Rocketlaunch" />}
          className="gap-2 sm:px-5 font-poppins font-semibold min-w-[236px] rounded-[20px]"
        >
          Financement
        </Button>
      </div>
    </header>
  );
}
