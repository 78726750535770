import React from "react";
import { Img } from "./..";

export default function HomepageDesktopLogobar({ ...props }) {
  return (
    <div {...props}>
      <div className="flex sm:flex-col justify-between items-center w-full mt-[5px] pr-10 gap-5 py-10 mx-auto sm:pr-5 sm:py-5 border-blue_gray-100 border-t border-b border-solid max-w-[1170px]">
        <Img src="images/img_client_logo_mark.png" alt="clientlogo_one" className="h-[64px] w-[64px] rounded-[50%]" />
        <div className="flex justify-center w-[18%] sm:w-full shadow-xs rounded-[38px]">
          <Img
            src="images/img_logo_3.png"
            alt="logothree_one"
            className="h-[76px] w-full md:h-auto object-cover rounded-[38px]"
          />
        </div>
        <div className="w-[12%] sm:w-full shadow-xs rounded-[57px]">
          <Img src="images/img_logo_4.png" alt="logofour_one" className="h-[115px] w-[115px] rounded-[50%]" />
        </div>
        <div className="w-[7%] sm:w-full mr-[198px] sm:mr-0 shadow-xs rounded-[32px]">
          <Img src="images/img_logo_5.png" alt="logofive_one" className="h-[64px] w-[64px] rounded-[50%]" />
        </div>
      </div>
    </div>
  );
}
