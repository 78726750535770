import React from "react";
import { Button, Text, Heading } from "./..";
import { useNavigate } from "react-router-dom";



export default function HomepageDesktopHerosection({
  
  bienvenuesur = (
    <>
      BIENVENUE SUR SENSEI <br />
      LE FUTUR DE L’EDUCATION
    </>
  ),
  description = "Bienvenue sur Sensei, la révolution de l'éducation. Sensei est une plateforme innovante d'accompagnement, dédiée à soutenir l'éducation et à faciliter l'accès aux aides financières pour les étudiants en Suisse. Notre objectif est de démocratiser l'éducation en offrant une plateforme sécurisée et transparente, connectant étudiants, donateurs et institutions éducatives.",
  financement = "Financer ma formation",
  ...props
}) {
  const navigate = useNavigate()

const gotToNewPage=()=>{
  navigate("https://cal.com/sensei1/financement");
}

  return (
    <div {...props}>
      <div className="flex flex-col self-stretch w-full mt-[65px] gap-5 mx-auto max-w-[1170px]">
        <Heading size="lg" as="h1" className="tracking-[-1.00px] text-center leading-[130%]">
          {bienvenuesur}
        </Heading>
        <Text as="p" className="text-center leading-[150%]">
          {description}
        </Text>
      </div>
      <div className="flex justify-center w-[18%] mb-[5px]">
        <div className="w-full">
          <Button onClick={() => gotToNewPage()} color="teal_600" size="md" className="w-full sm:px-5 font-poppins font-semibold rounded-[20px]">
            {financement}
          </Button>
        </div>
      </div>
    </div>
  );
}
