import React from "react";
import { Text, Heading, Img } from "./..";

export default function HomepageDesktopFeatures({
  commentca = "COMMENT CA FONCTIONNE :",
  writeafeature = "Conseils en orientation ",
  description = "Notre service de conseils en orientation est conçu pour offrir aux étudiants une aide spécifique et adaptée à leurs besoins individuels dans diverses matières. Nous mettons l'accent sur la mise en relation des étudiants avec des experts et des ressources qui les aideront à exceller dans leurs domaines d'étude.",
  writeafeature1 = "Transparence et suivi",
  description1 = "Sensei s'engage à être une ressource complète pour les étudiants, leur fournissant les outils et le soutien nécessaires pour réussir académiquement, professionnellement et personnellement. La plateforme est conçue pour être intuitive, engageante et facile d'accès, assurant que chaque étudiant trouve le soutien dont il a besoin.",
  writeafeature2 = "Demande d'aides financières",
  description2 = "Les étudiants éligibles peuvent postuler pour des aides financières via Sensei. Notre processus de demande est simplifié et transparent, offrant des chances équitables à tous les étudiants.",
  writeafeature3 = "Sélection des bénéficiaires",
  description3 = "Un comité d’experts examine attentivement toutes les demandes et sélectionne les bénéficiaires en fonction de critères spécifiques tels que le mérite académique surtout arrivé le plus loin possible, les besoins financiers et les objectifs éducatifs.",
  ...props
}) {
  return (
    <div {...props}>
      <div className="flex flex-col self-stretch w-full mt-[62px] gap-14 mx-auto sm:gap-7 max-w-[1170px]">
        <div className="flex px-[390px] md:px-5">
          <Heading size="s" as="h1" className="!text-black-900 tracking-[-0.28px] text-center">
            {commentca}
          </Heading>
        </div>
        <div className="flex md:flex-col justify-center gap-[30px]">
          <div className="w-full p-[30px] sm:p-5 border-teal-50 border border-solid bg-white-A700 shadow-sm rounded-[20px]">
            <div className="flex flex-col items-center gap-[26px] my-[22px]">
              <div className="flex flex-col items-center p-5 bg-red-A200_33 rounded-[20px]">
                <Img src="images/img_magicwand.svg" alt="magicwand_one" className="h-[42px] w-[42px]" />
              </div>
              <div className="flex flex-col self-stretch items-center gap-[22px]">
                <Heading as="h2" className="text-center">
                  {writeafeature}
                </Heading>
                <Text size="s" as="p" className="text-center leading-[150%]">
                  {description}
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-[29px] p-[30px] sm:p-5 border-teal-50 border border-solid bg-white-A700 shadow-sm rounded-[20px]">
            <div className="flex flex-col items-center mt-2.5 p-5 bg-blue-A700_26 rounded-[20px]">
              <Img src="images/img_user.svg" alt="user_one" className="h-[42px] w-[42px]" />
            </div>
            <div className="flex flex-col self-stretch items-center mb-2.5 gap-[19px]">
              <Heading as="h3" className="text-center">
                {writeafeature1}
              </Heading>
              <Text size="s" as="p" className="text-center leading-[150%]">
                {description1}
              </Text>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center w-full gap-[30px] p-[30px] sm:p-5 border-teal-50 border border-solid bg-white-A700 shadow-sm rounded-[20px]">
            <div className="flex flex-col items-center mt-11 p-5 bg-teal-600_33 rounded-[20px]">
              <div className="flex flex-col">
                <Img src="images/img_bookmarksimple.svg" alt="bookmarksimple" className="h-[42px]" />
              </div>
            </div>
            <div className="flex flex-col self-stretch mb-11 gap-[23px]">
              <Heading as="h4" className="text-center leading-[130%]">
                {writeafeature2}
              </Heading>
              <Text size="s" as="p" className="text-center leading-[150%]">
                {description2}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-[32%] mb-[65px] gap-[26px] p-[30px] sm:p-5 border-teal-50 border border-solid bg-white-A700 shadow-sm rounded-[20px]">
        <div className="flex flex-col items-center mt-2.5 p-5 bg-orange-A100_33 rounded-[20px]">
          <div className="flex flex-col">
            <Img src="images/img_rocketlaunch_5.svg" alt="rocketlaunchfiv" className="h-[42px]" />
          </div>
        </div>
        <div className="flex flex-col self-stretch items-center mb-2.5 gap-[22px]">
          <Heading as="h2" className="text-center">
            {writeafeature3}
          </Heading>
          <Text size="s" as="p" className="text-center leading-[150%]">
            {description3}
          </Text>
        </div>
      </div>
    </div>
  );
}
