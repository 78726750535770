import React from "react";
import { Button, Img, Text } from "./..";

export default function Footer({ ...props }) {
  return (
    <footer {...props}>
      <div className="flex sm:flex-col justify-between items-center w-full gap-5 mx-auto max-w-[1160px]">
        <Text size="xs" as="p" className="self-end mb-[5px] !text-white-A700">
          © 2024 Agartha’s Landing Page Ui Kit.{" "}
        </Text>
        <div className="flex gap-4">
          <Button shape="circle" className="w-[32px] !rounded-[16px]">
            <Img src="images/img_path.svg" />
          </Button>
          <Button shape="circle" className="w-[32px] !rounded-[16px]">
            <Img src="images/img_socialicons.svg" />
          </Button>
          <Button shape="circle" className="w-[32px] !rounded-[16px]">
            <Img src="images/img_social_icons_2.svg" />
          </Button>
          <Button shape="circle" className="w-[32px] !rounded-[16px]">
            <Img src="images/img_social_icons_3.svg" />
          </Button>
        </div>
      </div>
    </footer>
  );
}
